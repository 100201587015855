<template>
  <div id="seat-layout" class="light-gradient" :style="`background-size:cover; background-image:url('${require('@/assets/images/north-club/north-club-map.jpg')}')`">    
    <div class='add-padding'>
        <div class='limit-width'>        
            <div class="map">
                <MapViews path="north-club" :views="[{label:'Live Entertainment View', image:'club-seating-map'},{label:'Hockey View', image:'club-seating-map-hockey'}]"/>
                <div>
                    <PageTitle dark title="Club Pricing"/>                    
                    <div class='north-club-content'>
                        <h3>Events Included (Per Seat / Year)</h3>
                        <table>
                            <tr class='headers body caps'>
                                <th>All events <span>(Per seat)</span></th>
                                <th class='white-background align-center'>$10,499</th>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <ul>
                                        <li>Includes ticket for every event at Enmarket Arena (some exclusions may apply)</li>
                                        <li>Seating within first four rows of the floor/ice with best views for concerts</li>
                                        <li>Includes ECHL playoff tickets (all rounds)</li>
                                    </ul>
                                </td>
                            </tr>
                        </table>
                        <table>
                            <tr class='headers body caps'>
                                <th>Hockey Membership <span>(Per seat)</span></th>
                                <th class='white-background align-center'>$2,750 - $3,500 per seat</th>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <ul>
                                        <li>Same seat for all regular season home hockey games at Enmarket Arena</li>
                                        <li>First right of refusal to purchase your same seat for playoff hockey</li> 
                                        <li>First right of refusal to purchase your same seat for top concerts, family shows and other world-class events</li>
                                    </ul>
                                </td>
                            </tr>
                        </table>
                        <ul class="body">                            
                            <li>Club seating available in 3, 5 or 7-year terms.  Discounts may apply.</li>                        
                        </ul>
                    </div>
                </div>
            </div>            
        </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle';
import MapViews from '@/components/MapViews';
export default {
    name:'NorthClubMap',
    components: {        
        PageTitle,
        MapViews
    },
    filters: {
        toCurrency: function (value) {
            if (typeof value !== "number") {
                return value;
            }
            var formatter = new Intl.NumberFormat("en-US", {        
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }
        );
        return formatter.format(value);
        }
    },
    methods: {
        galleryUpdatedSlide(value) {
            this.activeSlide = value;
        }
    },
    data: () => ({   
      activeSlide:0,
      suites: [
          {
              title:'Suite 1',
              capacity:'14',
              cost:120000,            
          }, 
          {
              title:'Suite 2',
              capacity:'14',
              cost:120000,            
          }, 
          {
              title:'Suite 3',
              capacity:'14',
              cost:120000,            
          }, 
          {
              title:'Suite 4',
              capacity:'14',
              cost:120000,       
              sold:true     
          }, 
          {
              title:'Suite 5',
              capacity:'24',
              cost:135000,            
          }, 
          {
              title:'Suite 6',
              capacity:'24',
              cost:135000,            
              sold:true
          }, 
          {
              title:'Suite 7',
              capacity:'14',
              cost:110000,            
          }, 
          {
              title:'Suite 8',
              capacity:'14',
              cost:110000,            
          }, 
          {
              title:'Suite 9',
              capacity:'14',
              cost:110000,            
          }, 
          {
              title:'Suite 10',
              capacity:'14',
              cost:110000,            
          }, 
          {
              title:'Suite 11',
              capacity:'14',
              cost:110000,            
              sold:true
          }, 
          {
              title:'Suite 12',
              capacity:'14',
              cost:110000,            
              sold:true
          },          
      ]
  }),
}
</script>

<style lang="scss" scoped>
#seat-layout {
    padding-bottom:50px;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;    
    margin:15px 0;
    text-align: left;
    text-transform: uppercase;
    span {
        display: inline-block;
    }    
}
td, th {
    padding:10px;
    font-weight: inherit;
}
tr {
    background-color: rgba(32, 32, 32, 0.75);
    color:#fff;
    &.headers {
        background-color:#fff;
        color:#202020;
        text-transform: uppercase;
        .white-background {
            color:#fff;
            background-color:#D84350;
        }
    }
}
.align-center {
    text-align: center;
}
.north-club-content {
    position: relative;
    padding-bottom:90px;
    ul {
        margin:0;
        padding:0;
        padding-left:15px;
        li {
            margin:0;     
            margin-bottom:8px;       
            max-width: 500px;
        }
    }
}
.map {
    position: relative;
    display:flex; 
    padding-top:80px;
    gap:30px;
    > img {
        margin-right:65px;
        object-fit: contain;
        flex-grow: 1;
        max-height: 489px;
        flex-shrink: 1;
        width: 50%;
        object-position: top;        
    }   
}
</style>