<template>
  <div>
    <transition name="slide-fade" mode="out-in" appear>
      <SubNav :items="activePages"/>
    </transition>
    <div class='main'>
      <Component v-for="page in activePages" :key="page.id" :is="page.component" />        
      <Footer />
    </div>
  </div>
</template>

<script>
import { shallowRef } from 'vue'
import axios from 'axios';

import SubNav from '@/components/SubNav';
import Footer from '@/components/Footer';
import NorthClubExperience from '@/pages/NorthClubExperience';
import NorthClubPhotos from '@/pages/NorthClubPhotos';
import NorthClubMap from '@/pages/NorthClubMap';

const northClubExperience = shallowRef(NorthClubExperience)
const northClubPhotos = shallowRef(NorthClubPhotos)
const northClubMap = shallowRef(NorthClubMap)
export default {
  name: 'NorthClub',
  components: {
    SubNav,
    Footer,      
  },
  mounted() {
      if(this.$store.state.preview) {
        return;
      }
      const post = {
        method:"page",
        purl:this.$store.state.purl,
        sessionDbId:this.$store.state.sessionDbId,
        section:'5'
      }
      axios.post(this.$store.state.baseUrl + "EpitchLiteTracking.php", post);     
  },
  computed: {
    activePages() {
        let serverPages = this.$store.state.pages.filter(serverPage => {      
            return this.pages.findIndex(page => serverPage.id === page.id) === -1 ? false : true;                
        });
        let pages = [];
        serverPages.forEach(element => {
          pages.push({...element, ...this.pages[this.pages.findIndex(page => element.id === page.id)]});
          
        });
        return pages;
    }
  },
  data: () => ({         
      pages : [
          {
            id:'26',
            path : '/north-club',
            hash : 'north-club-photos',
            name : 'North Club Photos',
            component:northClubPhotos,
          },
          {
            id:'27',
            path : '/north-club',
            hash : 'your-experience',
            name : 'Your Experience',
            component:northClubExperience,
          },
          {
            id:'28',
            path : '/north-club',
            hash : 'seat-layout',
            name : 'Seat Layout',
            component:northClubMap,
          }
      ] 
  }),
}
</script>