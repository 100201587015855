<template>
  <div id="your-experience" class="dark-gradient" :style="`background-size:cover; background-image:url('${require('@/assets/images/north-club/your-experience/your-experience.jpg')}')`">    
      <div class='add-padding'>
        <div class='limit-width'>
            <PageTitle title="Experience It All"/>
            <div style="position:relative;">
                <div class='your-experience'>
                    <div>
                        <p class="white body">The Premium Club, located on the North Side, is Enmarket Arena’s most exclusive hospitality area, accessible only by Club Seat Members.  Enjoy amazing site lines while socializing with a drink from one of two premium bars in your own private club.</p>
                        <p class="white body">Transition from our private club space to your dedicated plush seat in a matter of seconds with an unobstructed view of every event.  Club membership includes a guaranteed spot in our VIP parking garage, ensuring you and your guests arrive with the care and hospitality you will come to expect with your premium experience.</p>
                        <p class="white body">Paired with exclusive benefits, these seats are the most sought-after seats in the arena.  Your entertainment experience will never be the same after becoming an inaugural club seat member.</p>
                    </div>
                    <img src="@/assets/images/north-club/your-experience/north-club-experience.jpg" alt="">
                </div>
                <div class='your-experience-cards'>   
                    <div class='cards'>
                        <Card hasHover :card="{icon:'north-club/your-experience/premium.svg',title:'Premium Seating' }"/>                        
                        <Card hasHover :card="{icon:'north-club/your-experience/bars.svg',title:'2 Premium Bars' }"/>                        
                        <Card hasHover :card="{icon:'north-club/your-experience/private.svg',title:'Private Club Space' }"/>                        
                        <Card hasHover :card="{icon:'north-club/your-experience/parking.svg',title:'VIP Parking Space' }"/>                                                
                    </div>
                </div>
            </div>
        </div> 
      </div> 
    </div> 
</template>

<script>
import PageTitle from '@/components/PageTitle';
import Card from '@/components/Card';
export default {
    name:'NorthClubExperience',
    components: {
        PageTitle,      
        Card,
    },
}
</script>

<style lang="scss" scoped>
.your-experience {
    display: flex;
    background: rgba(32, 32, 32, 0.75);
    > div, > img {
        flex-grow:1;
        width:50%;
    }
    > div {
        padding:25px;
    }
    p {
        &:not(:last-child) {
            padding-bottom:25px;
        }
    }
    img {
        flex-grow:1;
        object-fit: cover;
        width:50%;
    }
}
.your-experience-cards {
    margin-top:30px;
    padding-bottom:115px;
    .cards {
        display: grid;
        gap:15px;
        .card {
          width:100%;
            height:175px;            
        }
    }
}
@media (min-width:725px) {
  .your-experience-cards .cards {
    grid-template-columns: repeat(auto-fit, minmax(205px, 1fr));
  }
  
}
</style>